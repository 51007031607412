import { $fetch } from 'ohmyfetch'

const createInstance = () => {

  return $fetch.create({
    baseURL: process.env.VUE_APP_ORBIT_API_BASE_URL,
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export default createInstance
