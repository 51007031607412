import { User } from '@revolutionprep/types'

export const demoUser: User = {
  actorId: 247849,
  actorType: 'Student',
  addressString: '6901 Hollywood Blvd, Los Angeles, CA 90028',
  avatarUrl: 'https://localhost:3000/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeW40QlE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f60523e0c2c7b2a9bbe267fd084d79dd683e36bb/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2QzNKbGMybDZaVWtpRFRFd01IZ3hNREErQmpzR1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--a2b8f2e44acc6748ef6cda7f241e6bf1f2ea2917/blob',
  createdAt: '2015-11-17T17:43:01-05:00',
  email: 'user@aol.com',
  examsApiToken: 'e5c725723f06aa4159978861cab9f6b1569386c5-1-248956-student-5c57f58ac2c757b8c181ce9aec9dd30bf665dc1d',
  firstName: 'Student',
  fullName: 'Student Name',
  hasNoPassword: false,
  homeUrl: 'https://localhost:9030',
  hubspotHandle: null,
  id: 248956,
  language: 'en',
  lastName: 'Name',
  lastSignInAt: '2023-01-25T13:53:31-05:00',
  lockedAt: null,
  login: 'mockUser',
  phone: '',
  pushNotifications:true,
  roles: ['student'],
  signInCount: 1027,
  timeZone: 'America/New_York',
  title: 'Student',
  updatedAt: '2023-01-25T17:49:46-05:00',
  username: 'mockUser'
}
