// vue
import { ref } from 'vue'

// pinia
import { defineStore } from 'pinia'

// composables
import { useStorage, WatcherGenerator } from '@/composables/storage'

export const useGlobalStore = defineStore('global', () => {
  // composables
  const storage = useStorage()

  // state
  const isCrossOutEnabled = ref(false)
  const isLoadingNextModule = ref(false)

  // modal state
  const showModalAnnotate = ref(false)
  const showModalBreak = ref(false)
  const showModalCalculator = ref(false)
  const showModalExitExam = ref(false)
  const showModalHelp = ref(false)
  const showModalReferenceSheet = ref(false)
  const showModalReturnHome = ref(false)

  // popover state
  const showPopoverDirections = ref(false)
  // test day mode
  const isTestDayModeEnabled = ref(false)

  // watchers
  const statePropsToWatch: WatcherGenerator[] = [
    {
      propertyName: 'isCrossOutEnabled',
      valueToWatch: isCrossOutEnabled
    },
    {
      propertyName: 'isTestDayModeEnabled',
      valueToWatch: isTestDayModeEnabled
    }
  ]
  storage.doGenerateStateWatchers(statePropsToWatch, 'global')

  // actions
  function doCloseAllModalsAndPopovers () {
    const modalAndPopoverStates = [
      showModalAnnotate,
      showModalBreak,
      showModalCalculator,
      showModalExitExam,
      showModalHelp,
      showModalReferenceSheet,
      showModalReturnHome,
      showPopoverDirections
    ]

    modalAndPopoverStates.forEach((componentState) => {
      componentState.value = false
    })
  }

  return {
    doCloseAllModalsAndPopovers,
    isCrossOutEnabled,
    isLoadingNextModule,
    isTestDayModeEnabled,
    showModalAnnotate,
    showModalBreak,
    showModalCalculator,
    showModalExitExam,
    showModalHelp,
    showModalReferenceSheet,
    showModalReturnHome,
    showPopoverDirections
  }
})
