// vue
import { computed, ref } from 'vue'

// pinia + stores
import { defineStore, storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user'

// types
import { Nullable } from '@revolutionprep/types'
import {
  ExamSection,
  ExamSubject,
  Question,
  Transcript,
  TranscriptBreak,
  TranscriptContent,
  TranscriptContentPayload,
  TranscriptDetail,
  TranscriptPayload,
  TranscriptSection,
  TranscriptSubject
} from '@/types/transcript'

// plugins
import createExamsInstance from '@/plugins/repository/lib/createExamsInstance'
import createRepository from '@/plugins/repository/lib/createRepository'

// utilities
import { generateRandomTranscriptId } from '@/utils'
import { FetchOptions, $fetch } from 'ohmyfetch'

// composables
import { useResetPiniaStore } from '@/composables/resetPiniaStore'
import { useStorage, WatcherGenerator } from '@/composables/storage'

export const useTranscriptStore = defineStore(
  'transcript',
  () => {
    // setup repository
    const examsInstance = createExamsInstance()
    const repository = createRepository(examsInstance)
    const $transcripts = repository('/api/transcripts')

    // composables
    const resetPiniaStore = useResetPiniaStore()
    const storage = useStorage()

    // user
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    // state
    const activeQuestionIndex = ref(0)
    const activeSequence = ref<Nullable<number>>(null)
    const examTitle = ref('')
    const lastReadingAndWritingQuestionIndex = ref<Nullable<number>>(null)
    const lastMathQuestionIndex = ref<Nullable<number>>(null)
    const transcript = ref<Nullable<Transcript>>(null)
    const transcriptId = ref(0)
    const transcriptContent = ref<Nullable<TranscriptContent>>(null)

    // watchers
    const statePropsToWatch: WatcherGenerator[] = [
      {
        propertyName: 'activeQuestionIndex',
        valueToWatch: activeQuestionIndex
      },
      {
        propertyName: 'activeSequence',
        valueToWatch: activeSequence
      },
      {
        propertyName: 'examTitle',
        valueToWatch: examTitle
      },
      {
        propertyName: 'lastReadingAndWritingQuestionIndex',
        valueToWatch: lastReadingAndWritingQuestionIndex
      },
      {
        propertyName: 'lastMathQuestionIndex',
        valueToWatch: lastMathQuestionIndex
      },
      {
        propertyName: 'transcript',
        valueToWatch: transcript
      },
      {
        propertyName: 'transcriptContent',
        valueToWatch: transcriptContent
      },
      {
        propertyName: 'transcriptId',
        valueToWatch: transcriptId
      }
    ]
    storage.doGenerateStateWatchers(statePropsToWatch, 'transcript')

    // actions
    async function createTranscript (
      payload: TranscriptPayload,
      config: FetchOptions = {}
    ) {
      const _transcript: Transcript =
        await $transcripts.create(payload, config)
      transcript.value = _transcript
      return _transcript
    }

    async function deleteTranscript (transcriptId: number) {
      await $transcripts.delete(transcriptId, {
        headers: {
          authorization: user.value?.examsApiToken || ''
        }
      })
      resetPiniaStore.doResetPiniaStore().transcript()
    }

    function doValidateTranscript (validTranscriptId?: number) {
      if (validTranscriptId && validTranscriptId !== transcriptId.value) {
        throw new Error('Invalid transcriptId, please start a new exam.')
      }

      if (
        !transcript.value ||
        !transcriptContent.value ||
        !transcriptId.value
      ) {
        throw new Error('Something went wrong, please start a new exam.')
      }
      return
    }

    async function showTranscriptAndContent (
      config?: FetchOptions
    ): Promise<TranscriptContentPayload> {
      const transcriptContentPayload: TranscriptContentPayload =
        await $fetch(`${process.env.VUE_APP_DEMO_CONTENT_URL}`, {
          ...config,
          method: 'GET'
        })
      // set local storage with transcript content
      transcriptContent.value = transcriptContentPayload.content
      transcript.value = transcriptContentPayload.transcript
      transcriptId.value = generateRandomTranscriptId()
      return transcriptContentPayload
    }

    function _getAdaptiveSequenceType () {
      // If the section is adaptive, find the associated baseline section
      const baselineSectionNumber = activeExamSection.value?.baseline_section_number
      if (baselineSectionNumber) {
        const baselineSection = _getBaselineExamSection(baselineSectionNumber)
        /**
         * Compare the baseline section raw score threshold
         * to the transcript section computed raw score
         */
        const rawScoreThreshold = baselineSection?.raw_score_threshold || null
        const baselineSectionId = baselineSection?.id
        if (baselineSectionId) {
          const transcriptSection = _getBaselineTranscriptSection(baselineSectionId)
          const transcriptSectionRawScore = (transcriptSection as TranscriptSection)?.raw_score
          if (transcriptSectionRawScore !== null && rawScoreThreshold !== null) {
            /**
             * If the transcript section computed raw score >= threshold raw score,
             * only show hard questions; otherwise, only show easy questions
             */
            return transcriptSectionRawScore >= rawScoreThreshold
              ? 'sequence_high' // hard questions
              : 'sequence_low' // easy questions
          }
        }
      }
      return null
    }

    function _getBaselineExamSection (baselineSectionNumber: number) {
      return transcriptContent.value?.exam_sections?.find((
        examSection: ExamSection
      ) => {
        return examSection.section_number === baselineSectionNumber
      }) || null as Nullable<ExamSection>
    }

    function _getBaselineTranscriptSection (examSectionId: number) {
      return transcript.value?.transcript_sections?.find((
        transcriptSection: TranscriptSection | TranscriptBreak
      ) => {
        return transcriptSection?.kind === 'Section'
          ? (
              transcriptSection as TranscriptSection
            ).exam_section_id === examSectionId
          : false
      }) || null as Nullable<TranscriptSection>
    }

    // getters
    const activeExamSection = computed(() => {
      return transcriptContent.value?.exam_sections?.find(
        (examSection: ExamSection) => {
          return examSection.id === (
            activeTranscriptSectionOrBreak.value as TranscriptSection
          )?.exam_section_id
        })
    })

    const activeSectionTranscriptDetail = computed(() => {
      return activeTranscriptSection.value?.transcript_details?.find(transcriptDetail => {
        return transcriptDetail.question_id === activeQuestion.value?.id
      }) as TranscriptDetail
    })

    const activeTranscriptSectionOrBreak = computed(() => {
      return transcript.value?.transcript_sections?.find((
        transcriptSection: TranscriptSection | TranscriptBreak
      ) => {
        return transcriptSection.sequence === activeSequence.value
      }) as TranscriptSection | TranscriptBreak
    })

    const activeTranscriptSection = computed(() => {
      return (activeTranscriptSectionOrBreak.value?.kind === 'Section'
        ? activeTranscriptSectionOrBreak.value
        : null) as Nullable<TranscriptSection>
    })

    // duration of section or break
    const durationInSeconds = computed(() => {
      return activeTranscriptSectionOrBreak.value?.duration * 60 || 0
    })

    const examSubject = computed(() => {
      return transcriptContent.value?.exam_subjects?.find(
        (examSubject: ExamSubject) => {
          return examSubject.subject_id === transcriptSubject.value?.id
        }
      )
    })

    const examType = computed(() => {
      return transcriptContent.value?.exam.exam_type || ''
    })

    // exam name (without "Digital")
    const examName = computed(() => {
      return `${examType.value.replace('Digital ', '')}`
    })

    const examFormCode = computed(() => {
      return transcriptContent.value?.exam.form_code || ''
    })

    // active exam section questions
    const questions = computed(() => {
      const questions = activeExamSection.value?.questions || []
      if (sectionType.value === 'Adaptive') {
        // determine whether to use sequence_high or sequence_low
        const sequenceType = _getAdaptiveSequenceType()
        if (sequenceType) {
          return questions
            .filter((question: Question) => {
              // remove any null sequence_high or sequence_low
              return question[sequenceType] !== null
            })
            .map((question: Question) => {
              // add sequence property to adaptive section questions
              return {
                ...question,
                sequence: question[sequenceType]
              }
            })
          }
      }
      return questions
    })

    // question
    const activeQuestion = computed(() => {
      return questions.value[activeQuestionIndex.value] as Question
    })

    // type of active exam section (baseline or adaptive)
    const sectionType = computed(() => {
      return activeExamSection.value?.section_type
    })

    const sectionAndModuleNumber = computed(() => {
      switch (activeExamSection.value?.section_number) {
        case 1:
          return 'Section 1, Module 1'
        case 2:
          return 'Section 1, Module 2'
        case 3:
          return 'Section 2, Module 1'
        case 4:
          return 'Section 2, Module 2'
        default:
          return 'Section'
      }
    })

    const sectionSubject = computed(() => {
      return activeExamSection.value?.subject_name || 'N/A'
    })

    const sectionTitle = computed(() => {
      return `${sectionAndModuleNumber.value}: ${sectionSubject.value}`
    })

    const transcriptSubject = computed(() => {
      return transcript.value?.subjects?.find(
        (transcriptSubject: TranscriptSubject) => {
          return transcriptSubject.id === activeExamSection.value?.subject_id
        }
      )
    })

    return {
      activeExamSection,
      activeQuestion,
      activeQuestionIndex,
      activeSectionTranscriptDetail,
      activeSequence,
      activeTranscriptSection,
      activeTranscriptSectionOrBreak,
      createTranscript,
      deleteTranscript,
      doValidateTranscript,
      durationInSeconds,
      examFormCode,
      examTitle,
      examName,
      examSubject,
      lastMathQuestionIndex,
      lastReadingAndWritingQuestionIndex,
      questions,
      sectionTitle,
      sectionType,
      showTranscriptAndContent,
      transcript,
      transcriptContent,
      transcriptId,
      transcriptSubject
    }
  }
)
