// vue
import { ref } from 'vue'

// types
import { FetchError } from 'ohmyfetch'
import { ErrorHash } from '@revolutionprep/types'

// utilities
import { errorsToSentence } from '@revolutionprep/utils'

// pinia stores
import { storeToRefs } from 'pinia'
import { useToastStore } from '@/store/toast'
import { useErrorStore } from '@/store/error'

interface ErrorResponse {
  message: string
  statusCode: number
}

interface ErrorData {
  errors: ErrorHash
}

export function useErrorHandler () {
  const isUnauthorizedRequest = ref(false)
  const showedToast = ref(false)

  function _isRTCError (
    error: FetchError | RTCError | Error
  ): error is RTCError {
    return 'errorDetail' in error
  }

  async function doHandleError (
    error: FetchError | RTCError | Error,
    showErrorMessage = false,
    customErrorMessage = ''
  ): Promise<ErrorResponse> {

    // set error in pinia store if debug is on
    const errorStore = useErrorStore()
    const { latestError } = storeToRefs(errorStore)

    if (process.env.VUE_APP_DEBUG) {
      latestError.value = error
    }

    if (error instanceof FetchError) {
      const status = Number(error?.response?.status)
      if (error?.response) {
        // logout user if status is 401
        if (status === 401) {
          isUnauthorizedRequest.value = true
        }
        return _doHandleErrorResponse(
          error,
          showErrorMessage,
          customErrorMessage
        )
      } else {
        return _doHandleErrorResponse(
          {
            name: 'FetchError',
            response: {
              status: 500
            }
          } as FetchError,
          showErrorMessage,
          customErrorMessage
        )
      }
    } else if (_isRTCError(error)) {
      return _doHandleErrorResponse(
        error,
        showErrorMessage,
        customErrorMessage
      )
    } else {
      return _doHandleErrorResponse(
        error,
        showErrorMessage,
        customErrorMessage
      )
    }
  }

  function _doHandleErrorResponse (
    errorResponse: FetchError | RTCError | Error,
    showErrorMessage: boolean,
    customErrorMessage: string
  ): ErrorResponse {
    const toastStore = useToastStore()
    const { toastData } = storeToRefs(toastStore)

    let statusCode
    let message

    if (errorResponse instanceof FetchError) {
      statusCode = Number(errorResponse?.response?.status) || 500
      message = errorResponse?.data
        ? errorsToSentence((errorResponse.data as ErrorData).errors ||
          errorResponse.data, 'orbit')
        : _doErrorMessage(statusCode)
    } else if (_isRTCError(errorResponse)) {
      statusCode = 500
      message = errorResponse?.errorDetail
        ? errorResponse?.errorDetail
        : _doErrorMessage(statusCode)
    } else {
      statusCode = 500
      message = errorResponse?.message
        ? errorResponse?.message
        : _doErrorMessage(statusCode)
    }

    // display toast
    if (showErrorMessage) {
      if (!showedToast.value) {
        toastData.value = {
          isToastVisible: true,
          toastColor: 'danger',
          toastDuration: 5000,
          toastIcon: 'alertCircleOutline',
          toastMessage: customErrorMessage || message
        }
        // prevents toast from displaying twice
        if(isUnauthorizedRequest.value) {
          showedToast.value = true
        }
      }
    }

    return {
      message,
      statusCode
    }
  }

  function _doErrorMessage (status: number): string {
    switch (status) {
      case 401:
        return 'Session expired. You must login to perform this action.'
      case 403:
        return 'Forbidden. You do not have the required permissions to perform this action'
      case 404:
        return 'Not found. That record could not be found'
      case 422:
        return 'Unprocessable Entity. Your request could not be processed'
      default:
        return 'Sorry, our server has experienced an error. Please contact customer service.'
    }
  }

  return {
    doHandleError
  }
}
