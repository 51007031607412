// vue
import { ref } from 'vue'

// userflow
import userflow from 'userflow.js'

// composables
import { useErrorHandler } from '@/composables/errorHandler'

export default {
  install: async () => {
    // composables
    const { doHandleError } = useErrorHandler()

    // local state
    const isUserflowInitialized = ref(false)

    // initialize userflow
    if (process.env.VUE_APP_USERFLOW_TOKEN) {
      userflow.init(process.env.VUE_APP_USERFLOW_TOKEN)
      isUserflowInitialized.value = true
    }

    if (
      isUserflowInitialized.value &&
      !userflow.isIdentified()
    ) {
      try {
        await userflow.identifyAnonymous({
          is_demo_user: true
        })
      } catch (error) {
        doHandleError(error as Error, true)
      }
    }
  }
}
